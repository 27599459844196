import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, siteSubTitle }) => (
  <header
    style={{
      background: `white`,
      margin: `1.45rem 0 2.2rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0 1.18em`,
      }}
    >
      <div style={{ margin: 0 }}>
        <p style={{margin: 0}}>
          {siteSubTitle}
        </p>
        <p style={{
          margin: `-5px 0 0 0`,
          fontWeight: `bold`,
          color: `rgba(0, 0, 0, 0.2)`
        }}
        >
          {siteTitle}
        </p> 
      </div>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSubTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteSubTitle: ``,
}

export default Header
