/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title,
            subTitle
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} siteSubTitle={data.site.siteMetadata.subTitle}/>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0`,
          }}
        >
          <main>{children}</main>
        <footer  style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.18em`,
        }}>
        <p style={{margin: 0}}>
        {data.site.siteMetadata.subTitle}
      </p>
        <p style={{
          margin: `-5px 0 1em 0`,
          fontWeight: `bold`,
          color: `rgba(0, 0, 0, 0.2)`
        }}
        >
        {data.site.siteMetadata.title}
      </p> 
        Dipl.Des. Lucie Nienburg<br/>
        Vogelsbergstrasse 33<br/>
        60316 Frankfurt<br/>
        info[at]nienburg.design<br/>
        </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
