import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{maxWidth: `620px`, margin: `0`}}>
      <div style={{ maxWidth: `150px`, margin: `0 auto` }}>
        <Image />
      </div>
    </div>
    <div style={{margin: `4.2rem 0`, maxWidth: `620px`, padding: `0 1.18em`}}>
      <p>
        Wir entwickeln nützliche Produkte, die helfen, den Alltag nachhaltiger zu gestalten und die dazu ermuntern, sich von eingefahrenen Gewohnheiten zu lösen.
      </p>
      <p>
        Unsere Produkte zeichnen sich durch den effizienten Einsatz naturverträglicher Materialien aus und sind für eine multifunktionale Nutzung optimiert.
      </p>
      <p>
        Die Realisierung der Produkte erfolgt mit Unternehmen, die sich ihrer Verantwortung für die Umwelt bewusst sind, so dass bei den Serienprodukten von einem absolut nachhaltigem Erzeugnis ausgegangen werden kann.
      </p>
    </div>
  </Layout>
)

export default IndexPage
